export enum PlanType {
  SELF_SERVE = 'self_serve',
  ENTERPRISE = 'enterprise',
}

export enum Profile {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  CANARY = 'canary',
}

export const organizationProfileOptions = [
  { label: 'Internal', value: Profile.INTERNAL, key: Profile.INTERNAL },
  { label: 'External', value: Profile.EXTERNAL, key: Profile.EXTERNAL },
  { label: 'Canary', value: Profile.CANARY, key: Profile.CANARY },
];

export type OrganizationCapabilities = {
  [key in OrganizationCapabilityKeys]: boolean;
};

export enum OrganizationCapabilityKeys {
  ORGANIZATION_SSO = 'organization_sso',
  BILLING_ACCOUNT = 'billing_account',
  AURA_LATEST = 'aura_latest',
}
export interface Organization {
  id: string;
  ssoOrgId: string;
  planType: PlanType;
  profile: Profile;
  name: string;
  displayName: string;
  passwordLoginEnabled: boolean;
  googleLoginEnabled: boolean;
  copilotEnabled: boolean;
  capabilities: OrganizationCapabilities;
  trialEndTime?: Date;
}
export interface TrialCustomerInfo {
  firstName: string;
  lastName: string;
  jobRole: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  companyName: string;
  country: string;
  subdivision?: string;
}

export interface TrialDetails {
  trialEndTime: Date;
  trialInitialEndTime: Date;
  trialStartTime: Date;
}
