import React, { useMemo } from 'react';
import formatDollars from 'utils/format-dollars';
import { calcMonthlyCost } from './capacities/utils';
import {
  DatabaseSize,
  isSizeAvailableForVersion,
  isSizeAvailableInRegion,
  gibibytesStringToInt,
  isSizeEnabledByToggle,
  Tier,
} from 'entities/database';
import { BillingMethod } from 'entities/tenant';
import {
  Alert,
  DataGrid,
  useDefaultTable,
  createColumnHelper,
  Radio,
  DataGridComponents,
  Label,
  Typography,
} from 'foundation';
import classnames from 'classnames';
import './db-sizes.css';
import { useSession } from 'store';

export interface DatabaseSizePickerProps {
  value: DatabaseSize;
  options: DatabaseSize[];
  selectedRegion: string;
  cloudProvider: string;
  selectedVersion: string;
  hidePricing?: boolean;
  hideMonthlyCost?: boolean;
  errorMessage?: string;
  onChange: (value: DatabaseSize) => void;
  initialSize?: DatabaseSize;
  resizeThreshold?: number;
  tier?: Tier;
}

const headerHelper = createColumnHelper<DatabaseSize>();

export const DatabaseSizePicker = ({
  value,
  options,
  hidePricing,
  selectedRegion,
  cloudProvider,
  selectedVersion,
  onChange,
  errorMessage,
  hideMonthlyCost,
  initialSize,
  resizeThreshold = 0,
  tier,
}: DatabaseSizePickerProps) => {
  const { tenant } = useSession();
  const isPrepaidTenant = tenant.billingMethod === BillingMethod.PREPAID;
  const isPrepaidOnlyOptionSelected = options.find(opt => opt === value && opt?.prepaid_only);

  const isSizeAvailable = (sizeId: string) => {
    const size = options.find(obj => obj.size_id === sizeId);
    return (
      isSizeAvailableInRegion(size, selectedRegion) &&
      isSizeAvailableForVersion(size, selectedVersion, tenant) &&
      isSizeEnabledByToggle(size, tenant, cloudProvider)
    );
  };

  const getAriaLabel = (size: DatabaseSize) => {
    if (size.is_trial) {
      return `Trial option with ${size.memory} memory, ${size.cpu} CPU and ${size.storage} storage `;
    }
    return `${size.memory} memory with ${size.cpu} CPU and ${size.storage} storage${
      !hidePricing ? ` for ${formatDollars(size.cost_per_hour)} per hour` : ''
    }.`;
  };

  const isWithinStorageThreshold = (storage: string) => {
    return gibibytesStringToInt(storage) > resizeThreshold;
  };

  const isRowDisabled = (size: DatabaseSize) => {
    return (
      size.size_id === initialSize?.size_id ||
      !isSizeAvailable(size.size_id) ||
      !isWithinStorageThreshold(size.storage)
    );
  };

  const buildContactUrl = () => {
    const contactUrlTierMap = {
      free: 'free',
      mte: 'business-critical',
      professional: 'pro',
      gds: 'gds',
      enterprise: 'vdc',
      dsenterprise: 'dsenterprise',
    };
    let params = '';

    if (tier && value) {
      params = `?about_me=${contactUrlTierMap[tier]}-${cloudProvider}-${value.memory}`;
    }

    return `https://neo4j.com/contact-us/${params}`;
  };

  const columns = useMemo(
    () => [
      headerHelper.accessor('size_id', {
        cell: cx => {
          const dbSize: DatabaseSize = cx.row.original;
          return (
            <Radio
              className="tw-place-self-center tw-justify-self-center pl-2"
              data-testid={`size-radio-${dbSize.size_id}`}
              disabled={isRowDisabled(dbSize)}
              checked={cx.row.getIsSelected()}
              onChange={() => onChange(dbSize)}
              aria-label={getAriaLabel(dbSize)}
            />
          );
        },
        header: null,
        size: hidePricing ? 100 : 50,
      }),
      headerHelper.accessor('memory', {
        header: () => 'Memory',
        cell: cell => cell.getValue(),
        size: hidePricing ? 200 : 120,
      }),
      headerHelper.accessor('cpu', {
        header: () => 'CPU',
        cell: cell => `${cell.getValue()} CPU`,
        size: hidePricing ? 200 : 120,
      }),
      headerHelper.accessor('storage', {
        header: () => 'Storage',
        cell: cell => cell.getValue(),
      }),
      headerHelper.accessor('cost_per_hour', {
        header: () => 'Price',
        enableHiding: true,
        size: 300,
        cell: cell => {
          const dbSize = cell.row.original;
          const isTrial = dbSize.is_trial;
          const isPrepaidOnly = dbSize.prepaid_only;
          if (isPrepaidOnly && !isPrepaidTenant) {
            return (
              <span data-testid={`${dbSize.size_id}-prepaid-only`}>
                Only available with prepaid billing
              </span>
            );
          }
          if (isTrial) {
            return (
              <Label color="info" fill="outlined">
                14 day free trial
              </Label>
            );
          }
          return (
            <>
              {formatDollars(cell.getValue())}
              /hour{' '}
              {!hideMonthlyCost && `(${formatDollars(calcMonthlyCost(cell.getValue()))}/month)`}
            </>
          );
        },
      }),
    ],
    [onChange]
  );

  const table = useDefaultTable({
    columns,
    data: options,
    enableSorting: false,
    initialState: {
      pagination: { pageSize: 1000 },
      columnVisibility: { cost_per_hour: !hidePricing },
    },
    state: { rowSelection: { [options.indexOf(value)]: true } },
  });

  return (
    <div className="db-sizes-table">
      <DataGrid
        tableInstance={table}
        isResizable={false}
        styling={{ headerStyle: 'clean', borderStyle: 'none' }}
        components={{
          Navigation: null,
          Body: props => (
            <DataGridComponents.Body
              {...props}
              innerProps={{
                style: {
                  borderTop: '1px solid rgb(var(--theme-palette-neutral-border-weak))',
                },
              }}
            />
          ),
          BodyRow: ({ row }) => {
            const dbSize = row.original as DatabaseSize;
            const disabled = isRowDisabled(dbSize);
            const ariaLabel = getAriaLabel(dbSize);

            let title: string;

            if (!isSizeEnabledByToggle(dbSize, tenant, cloudProvider)) {
              title = 'Please raise a support ticket to request this option for your project';
            } else if (!isSizeAvailable(dbSize.size_id)) {
              title = 'This option is not available in the selected region/version';
            } else if (dbSize.size_id === initialSize?.size_id) {
              title = "Can't resize to existing size";
            } else if (gibibytesStringToInt(dbSize.storage) < resizeThreshold) {
              title = 'Existing data is too large for an instance of this size';
            } else {
              title = ariaLabel;
            }

            const handleChange = () => {
              if (disabled) return;
              onChange(dbSize);
            };

            const classes = classnames(
              `
              ndl-data-grid-tr
              tw-cursor-pointer
              tw-h-[50px]
              tw-items-center
              !tw-rounded-lg
            `,
              {
                'tw-cursor-not-allowed': disabled,
              }
            );

            return (
              <div
                key={row.id}
                className={classes}
                data-testid={`size-row-${dbSize.size_id}`}
                aria-label={ariaLabel}
                aria-disabled={disabled}
                title={title}
                onClick={handleChange}
                style={{
                  ...(row.getIsSelected() && {
                    backgroundColor: row.getIsSelected()
                      ? 'rgb(var(--theme-palette-neutral-bg-default))'
                      : 'inherit',
                  }),
                }}
              >
                {row.getVisibleCells().map(
                  cell =>
                    DataGridComponents.BodyCell && (
                      <DataGridComponents.BodyCell
                        key={cell.id}
                        cell={cell}
                        innerProps={{
                          className: classnames({
                            'ndl-data-grid-td-selected': row.getIsSelected(),
                            'ndl-data-grid-td-disabled': disabled,
                          }),
                        }}
                      />
                    )
                )}
              </div>
            );
          },
        }}
      />
      {isPrepaidOnlyOptionSelected && !isPrepaidTenant && (
        <Alert
          icon
          type="warning"
          className="tw-mt-6"
          title="Prepaid billing required"
          actions={[
            {
              href: buildContactUrl(),
              label: 'Contact sales',
              target: '_blank',
              className: 'tw--mt-2',
            },
          ]}
        >
          <Typography variant="body-medium">
            Large database sizing options are only available through prepaid billing. Please contact
            our sales team to proceed.
          </Typography>
        </Alert>
      )}
      {errorMessage && (
        <Alert type="danger" className="tw-mt-2">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};
