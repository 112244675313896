import React, { useState } from 'react';
import { Alert, Button, DateTimePicker, IconButton, LoadingSpinner } from 'components/foundation';
import { Organization, TrialDetails } from 'types/organization';
import LoadError from 'components/application/load-error';
import {
  useOrganizationQuery,
  updateTrial,
  useTrialDetailsQuery,
} from 'remote/resources/organizations';
import { useOrganizationTenantsQuery } from 'remote/resources/tenants';
import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import { PermissionTip } from 'components/application/permission-tip';
import { formatRFC7231 } from 'date-fns';
import { Tenant } from 'entities/tenant';
import { usePermissions } from 'store';
import { Action } from 'types/user';

const OrganizationTrialGuard = (
  component: React.ComponentType<{
    organization: Organization;
    organizationTenants: Tenant[];
    trialDetails: TrialDetails;
    onRefreshOrganization: () => Promise<any>;
    onRefreshTrialDetails: () => Promise<any>;
  }>
) => ({ organization }: { organization: Organization }) => {
  const Component = component;
  const organizationQuery = useOrganizationQuery(organization.id); // eslint-disable-line
  const organizationTenantsQuery = useOrganizationTenantsQuery(organization.id); // eslint-disable-line
  const trialDetailsQuery = useTrialDetailsQuery(organization.id); // eslint-disable-line

  if (
    organizationQuery.isLoading ||
    organizationTenantsQuery.isLoading ||
    trialDetailsQuery.isLoading
  ) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }
  if (
    organizationQuery.isError ||
    organizationTenantsQuery.isError ||
    (trialDetailsQuery.isError && organization.trialEndTime !== null)
  ) {
    return <LoadError />;
  }

  return (
    <Component
      organization={organization}
      organizationTenants={organizationTenantsQuery.data}
      trialDetails={trialDetailsQuery.data}
      onRefreshOrganization={organizationQuery.refetch}
      onRefreshTrialDetails={trialDetailsQuery.refetch}
    />
  );
};

const EditIcon = ({ allowEdit, onClick }) => (
  <PermissionTip hasPermission={allowEdit}>
    <IconButton
      title="Edit Trial End Time"
      aria-label="Edit Trial End Time"
      iconName="PencilIconOutline"
      disabled={!allowEdit}
      onClick={onClick}
      clean
      size="small"
    />
  </PermissionTip>
);

export const OrganizationTrialBase = ({
  organization,
  organizationTenants,
  trialDetails,
  onRefreshOrganization,
  onRefreshTrialDetails,
}: {
  organization: Organization;
  organizationTenants: Tenant[];
  trialDetails: TrialDetails;
  onRefreshOrganization: () => Promise<any>;
  onRefreshTrialDetails: () => Promise<any>;
}) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const toggleEditEnabled = () => {
    setEditEnabled(!editEnabled);
  };
  const [datePickerValue, setDatePickerValue] = useState(organization.trialEndTime);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { allow } = usePermissions();

  if (!trialDetails || !trialDetails.trialEndTime) {
    return <Alert>There is no active or expired trial on this organization.</Alert>;
  }

  const handleCancel = () => {
    setDatePickerValue(trialDetails.trialEndTime);
    toggleEditEnabled();
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setLoading(true);
    try {
      await updateTrial(organization.id, datePickerValue);
      await onRefreshOrganization();
      await onRefreshTrialDetails();
      setSuccessMessage('Trial end time updated successfully');
    } catch (err) {
      setErrorMessage(err.responseMessage || err.reason || 'Unknown error');
      setDatePickerValue(trialDetails.trialEndTime);
    }
    toggleEditEnabled();
    setLoading(false);
  };

  const hasBilling = organizationTenants.some(tenant => tenant.hasBilling);
  const hasBeenExtended =
    trialDetails.trialEndTime.getTime() !== trialDetails.trialInitialEndTime.getTime();
  const showEditButton = !hasBilling && !hasBeenExtended;
  const allowEdit = allow(Action.UPDATE, `internal/organizations/${organization.id}/trial`);

  return trialDetails.trialEndTime ? (
    <div className="tw-flex tw-flex-row tw-justify-start">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-ml-8 tw-mb-8">
        <KeyPairSection>
          <KeyPairItem label="Trial Start Time" isCopyable={false} className="tw-h-10">
            {formatRFC7231(trialDetails.trialStartTime)}
          </KeyPairItem>
          <KeyPairItem label="Trial Initial End Time" isCopyable={false} className="tw-h-10">
            {formatRFC7231(trialDetails.trialInitialEndTime)}
          </KeyPairItem>
          <KeyPairItem label="Trial End Time" isCopyable={false} className="tw-h-10">
            <div className="tw-flex tw-items-start tw-gap-2">
              {editEnabled ? (
                <DateTimePicker
                  value={datePickerValue}
                  onChange={d => setDatePickerValue(d)}
                  showTimeInput
                  minDate={trialDetails.trialStartTime}
                  minTime={trialDetails.trialStartTime}
                />
              ) : (
                formatRFC7231(trialDetails.trialEndTime)
              )}
              {editEnabled ? (
                <>
                  <Button onClick={handleCancel} fill="outlined" color="neutral">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={trialDetails.trialEndTime.getTime() === datePickerValue.getTime()}
                  >
                    Save
                  </Button>
                </>
              ) : (
                showEditButton && <EditIcon allowEdit={allowEdit} onClick={toggleEditEnabled} />
              )}
            </div>
          </KeyPairItem>
        </KeyPairSection>
        {errorMessage && (
          <Alert type="danger" className="tw-w-full">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert type="success" className="tw-w-full">
            {successMessage}
          </Alert>
        )}
      </div>
    </div>
  ) : (
    <Alert>There is no active or expired trial on this organization</Alert>
  );
};

export const OrganizationTrial = OrganizationTrialGuard(OrganizationTrialBase);
